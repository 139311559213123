import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { PliInputChangeEvent } from 'pli/controllers/input-controller';
import './comment-form';

@customElement('reply-comment-form')
class ReplyCommentForm extends LitElement {
    @state()
    _value = '';

    private onChange(event: PliInputChangeEvent) {
        this._value = event.detail.value;

        this.dispatchEvent(
            new CustomEvent('change', {
                composed: true,
                detail: {
                    value: this._value,
                },
            }),
        );
    }
    private onCancel() {
        this.dispatchEvent(new CustomEvent('cancel', { composed: true }));
    }

    private onSubmit() {
        this.dispatchEvent(new CustomEvent('submit', { composed: true, detail: { value: this._value } }));
    }

    render() {
        return html` <comment-form-layout>
            <pli-textarea
                slot="input"
                value="${this._value}"
                placeholder="Your comment"
                @change="${this.onChange}"
                .focusOnMount="${true}"
            ></pli-textarea>
            <div class="flex items-center gap-1 pl-3-5" slot="button-group">
                <pli-button variant="text" .onClick="${() => this.onCancel()}">Cancel</pli-button>
                <pli-button variant="text" .onClick="${() => this.onSubmit()}" .disabled="${!this._value}">
                    Comment
                </pli-button>
            </div>
        </comment-form-layout>`;
    }
}
