import { consume } from '@lit/context';
import { currentUserContext, CurrentUser } from 'context/current-user-context';
import { html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { styles } from 'pli/styles';
import { UserModel } from 'user-model';
import { getRelativeDiff } from 'utils/datetime-formatter';
import '../../pli/pli-user-bubble';

@customElement('comment-form-layout')
class CommentFormLayout extends LitElement {
    static styles = [styles.flex, styles.grid];
    p;
    @consume({ context: currentUserContext })
    currentUser?: CurrentUser;

    @state()
    _profile: UserModel = new UserModel();

    @property({ attribute: false })
    createdTime?: Date;

    async connectedCallback() {
        super.connectedCallback();
        if (!this.currentUser) {
            return;
        }
        this._profile = await this.currentUser.get();
    }

    render() {
        return html`<div class="flex gap-1">
            <pli-user-bubble
                firstName="${this._profile?.firstName}"
                lastName="${this._profile?.lastName}"
                userId="${this._profile?.userId}"
            ></pli-user-bubble>

            <div class="flex-1 grid-vertical gap-05">
                ${when(this.createdTime, () => html`<pli-text>${getRelativeDiff(this.createdTime)}</pli-text>`)}
                <slot name="input"></slot>
                <slot name="button"></slot>
                <slot name="button-group"></slot>
            </div>
        </div>`;
    }
}
