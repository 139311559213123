import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { PliStatusLabelVariant } from 'pli/pli-status-label';
import { styles } from 'pli/styles';

@customElement('displayed-status-change')
export class DisplayedStatusChange extends LitElement {
    @property()
    type: 'alert' | 'case' | 'report' = 'alert';

    @property()
    from: PliStatusLabelVariant = 'Opened';

    @property()
    to: PliStatusLabelVariant = 'Closed';

    static styles = [
        styles.flex,
        styles.grid,
        css`
            :host {
                display: block;
            }
        `,
    ];

    render() {
        return html` <div class="grid-vertical gap-05">
            <div class="flex justify-center gap-1">
                <pli-status-label variant="${this.from}"></pli-status-label>
                <pli-text>-></pli-text>
                <pli-status-label variant="${this.to}"></pli-status-label>
            </div>
            <div class="flex justify-center">
                <pli-text><strong>Change in ${this.type} status</strong></pli-text>
            </div>
        </div>`;
    }
}
