import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from 'pli/styles';

import 'pli/pli-icon';
import 'pli/pli-text';

import { PliIconProps } from 'pli/pli-icon';

import { classMap } from 'lit/directives/class-map.js';
import { formatNumber } from 'utils/number-format';

export const ValueState = ['positive', 'negative', 'by-value'] as const;
type PliValueStateName = (typeof ValueState)[number];

@customElement('displayed-amount')
class DisplayedAmount extends LitElement {
    static styles = [
        styles.flex,
        styles.grid,
        css`
            .positive {
                color: var(--color-jungle-green);
            }

            .negative {
                color: var(--button-background-destructive);
            }
        `,
    ];
    @property({ type: Number })
    value: number = 0;

    @property()
    currency?: string;

    @property()
    state: PliValueStateName = 'positive';

    isPositive() {
        if (this.state === 'positive') {
            return true;
        }
        else if (this.state === 'negative') {
            return false;
        }
        else if (this.value >= 0) {
            return true;
        }

        return false;
    }

    render() {
        const { value, currency } = this;
        const positive = this.isPositive();
        const icon: PliIconProps['name'] = positive ? 'arrow-up-short' : 'arrow-down-short';
        const displayedValue = Number.isNaN(value) ? 0 : formatNumber(value.toString());

        const classes = classMap({
            positive: positive,
            negative: !positive,
            'flex items-center gap-05': true,
        });

        return html`
            <div class="${classes}">
                <pli-icon name="${icon}"></pli-icon>
                <pli-text>${displayedValue} ${currency}</pli-text>
            </div>
        `;
    }
}
