import { LitElement, css, html } from 'lit';
import { customElement, state, property } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';

import 'pli/pli-card';
import 'pli/pli-text';
import 'pli/pli-user-bubble';
import 'pli/pli-textarea';
import 'pli/pli-button';
import 'pli/pli-icon';

import { consume } from '@lit/context';
import { CurrentUser, currentUserContext } from 'context/current-user-context';
import { UserModel } from 'user-model';
import { styles } from 'pli/styles';
import { getRelativeDiff } from 'utils/datetime-formatter';
import { PliInputChangeEvent } from 'pli/controllers/input-controller';
import { repeat } from 'lit/directives/repeat.js';
import '../../pli/pli-dialog';
import { GetCaseCommentList_ItemResult, GetCustomerCommentList_ItemResult } from 'tms-client';

import './add-new-comment-form';
import './comment-form';

type CommentModel = GetCustomerCommentList_ItemResult | GetCaseCommentList_ItemResult;

@customElement('comments-area')
class CommentsArea extends LitElement {
    static styles = [styles.grid, styles.flex, styles.padding, styles.form, css``];

    @property({ type: Array })
    comments: CommentModel[];

    @property({ type: Boolean, reflect: true })
    disabled?: boolean;

    @consume({ context: currentUserContext })
    currentUser?: CurrentUser;

    @state()
    _value: string | null = null;

    @state()
    _profile: UserModel = new UserModel();

    @state()
    _activeCommentFormId: CommentModel['commentId'] | null = null;

    async connectedCallback() {
        super.connectedCallback();

        if (!this.currentUser) {
            return;
        }

        this._profile = await this.currentUser.get();
    }

    onChange = (event: PliInputChangeEvent) => {
        this._value = event.detail.value;
    };

    resetState = () => {
        this._value = null;
        this.setActiveModeForComment(null);
    };

    add = async () => {
        const _event = new CustomEvent('add', {
            composed: true,
            detail: {
                value: this._value,
            },
        });
        this.dispatchEvent(_event);
        this.resetState();
    };

    edit = async (commentId: CommentModel['commentId']) => {
        const _event = new CustomEvent('edit', {
            composed: true,
            detail: {
                value: this._value,
                commentId: commentId,
            },
        });
        this.dispatchEvent(_event);
        this.resetState();
    };

    reply = async (item: CommentModel, value: string) => {
        const _event = new CustomEvent('reply', {
            composed: true,
            detail: {
                value,
                parentId: item.parentId ?? item.commentId,
            },
        });
        this.dispatchEvent(_event);
        this.resetState();
    };

    cancel() {
        const _event = new CustomEvent('cancel', { composed: true });
        this.dispatchEvent(_event);
    }

    _renderDeleteDialog = (commentId: CommentModel['commentId']) => {
        const handleDelete = async () => {
            const _event = new CustomEvent('delete', {
                composed: true,
                detail: {
                    value: commentId,
                },
            });
            this.dispatchEvent(_event);
            this.resetState();
        };

        return html`
            <pli-dialog>
                <pli-dialog-open-button
                    class="flex justify-end items-center"
                    slot="open-button"
                    variant="text"
                    width="hug-content"
                    size="md"
                    ><pli-icon name="trash" slot="icon-left"></pli-icon>Delete</pli-dialog-open-button
                >

                <pli-dialog-content
                    ><pli-icon-box slot="icon" name="question-circle" color="blue"></pli-icon-box>
                    <pli-text variant="h3">Delete comment?</pli-text>
                    <p>Are you sure you want to delete the comment? This action cannot be undone..</p>
                </pli-dialog-content>

                <pli-dialog-close-button slot="secondary-button">Cancel</pli-dialog-close-button>
                <pli-dialog-primary-button
                    @pli-dialog-primary-dismiss="${() => handleDelete()}"
                    variant="destructive"
                    slot="primary-button"
                    >Delete</pli-dialog-primary-button
                >
            </pli-dialog>
        `;
    };

    get hasValue(): boolean {
        return this._value !== null && this._value.length > 0;
    }

    get isEditingOrReplying(): boolean {
        return this._activeCommentFormId !== null;
    }

    setActiveModeForComment = (commentId: CommentModel['commentId'] | null) => {
        this._activeCommentFormId = commentId;
    };

    isCommentFormActive(commentId: CommentModel['commentId']) {
        return Boolean(this._activeCommentFormId) && this._activeCommentFormId !== commentId;
    }

    renderComment = (comment: CommentModel) => {
        const commentHasReplies = Boolean(comment.children.length);

        const renderTemplate = (item?: CommentModel) => {
            if (!item) {
                return null;
            }

            return html`
                <div .inert="${this.isCommentFormActive(item.commentId)}">
                    <comment-form
                        value="${item.text}"
                        .createdTime="${new Date(item.createdTime)}"
                        @change="${this.onChange}"
                        @reply="${() => this.setActiveModeForComment(item.commentId)}"
                        @replySubmit="${(event) => this.reply(item, event.detail.value)}"
                        @replyCancel="${() => this.setActiveModeForComment(null)}"
                        @editSubmit="${() => this.edit(item.commentId)}"
                        @edit="${() => this.setActiveModeForComment(item.commentId)}"
                        @editCancel="${() => this.setActiveModeForComment(null)}"
                    >
                        <pli-text slot="timestamp">${getRelativeDiff(item.createdTime)}</pli-text>
                        <div slot="delete-dialog">${this._renderDeleteDialog(item.commentId)}</div>
                    </comment-form>
                </div>
            `;
        };

        return html`
            <div class="grid-vertical gap-2">
                <!-- Main comment -->
                ${renderTemplate(comment)}
                <!-- Replies -->
                ${when(
                    commentHasReplies,
                    () => html`
                        <div class="pl-3-5 grid-vertical gap-1">
                            ${repeat(comment.children, (item) => renderTemplate({ ...item }))}
                        </div>
                    `,
                )}
            </div>
        `;
    };

    render() {
        return html`
            <pli-card>
                <div class="grid-vertical gap-2">
                    <slot></slot>
                    ${when(
                        this.comments?.length,
                        () => html`
                            <div class="pl-3-5 flex-1 grid-vertical gap-2">
                                ${this.comments.map((comment) => this.renderComment(comment))}
                            </div>
                        `,
                    )}
                    ${when(
                        !this.disabled && !this.isEditingOrReplying,
                        () => html`
                            <add-new-comment-form
                                @submit="${this.add}"
                                @change="${this.onChange}"
                            ></add-new-comment-form>
                        `,
                    )}
                </div>
            </pli-card>
        `;
    }
}
