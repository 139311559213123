import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { PliInputChangeEvent } from 'pli/controllers/input-controller';
import './comment-form';

@customElement('add-new-comment-form')
class AddNewCommentForm extends LitElement {
    @property()
    value: string = '';

    get hasValue() {
        return Boolean(this.value) && this.value.length > 0;
    }

    clearValue() {
        this.value = '';
    }

    handleSubmit() {
        this.dispatchEvent(
            new CustomEvent('submit', {
                composed: true,
                detail: {
                    value: this.value,
                },
            }),
        );

        this.clearValue();
    }

    handleChange(event: PliInputChangeEvent) {
        this.value = event.detail.value;
        this.dispatchEvent(
            new CustomEvent('change', {
                composed: true,
                detail: {
                    value: this.value,
                },
            }),
        );
    }

    render() {
        return html`<comment-form-layout>
            <pli-textarea
                slot="input"
                value="${this.value}"
                placeholder="Your comment"
                @change="${(event: PliInputChangeEvent) => this.handleChange(event)}"
            ></pli-textarea>
            <pli-button slot="button" .onClick="${() => this.handleSubmit()}" size="lg" .disabled="${!this.hasValue}"
                >Comment</pli-button
            >
        </comment-form-layout>`;
    }
}
