import { LitElement, TemplateResult, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import 'pli/pli-card';
import 'pli/pli-text';
import 'pli/pli-icon';
import 'pli/pli-button';
import 'pli/pli-table';
import 'pli/pli-pagination';
import './displayed-amount';
import './transaction-graph';

import { styles } from 'pli/styles';
import { Task } from '@lit/task';
import { consume } from '@lit/context';
import { PliButtonProps } from 'pli/pli-button';
import { defineHeaderItems } from 'pli/pli-table';
import { getFormattedTime } from 'utils/datetime-formatter';
import { GraphVariant, TransactionGraphSliceEvent } from './transaction-graph';
import { useSkip } from 'utils/use-pagination-helpers';
import '../../pli/pli-pager';
import { PliPagerUpdatedEvent } from '../../pli/pli-pager';
import {
    getTransactionBars,
    GetTransactionBarsResponse,
    getTransactionList,
    GetTransactionList_TransactionItem,
    GetTransactionListResponse,
    getTransactionPeriodList,
    GetTransactionPeriodList_TimeSeriesPeriodRequest,
} from 'tms-client';
import '../../pli/pli-dialog';
import '../../components/property-view/poc/property-view';
import '../../components/dialog-content/transaction/transaction-dialog-content';
import { when } from 'lit/directives/when.js';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';

@customElement('transaction-history-area')
class TransactionHistoryArea extends LitElement {
    static styles = [
        styles.grid,
        styles.flex,
        styles.list,
        styles.padding,
        styles.divider,
        css`
            .positive {
                color: var(--color-cerulean);
            }

            .negative {
                color: var(--button-background-destructive);
            }
        `,
    ];

    @property({ type: String, reflect: true })
    customerId: string;

    @consume({ context: errorDialogContext })
    errorDialog: ErrorDialogController;

    /* Tasks */
    _transactionHistoryTask = new Task(this, {
        task: async ([period]) => {
            const { error, data } = await getTransactionPeriodList({
                path: { customerId: this.customerId, period },
            });
            if (error) {
                return this.errorDialog.showError({ bodyText: `Could not retrieve transactions for period ${period}` });
            }
            this._transactionHistory = data;
            this.setTimeWindow();
        },
        args: () => [this._selectedPeriod] as const,
    });
    _transactionHistorySliceTask = new Task(this, {
        task: async ([from, to]) => {
            const { error, data } = await getTransactionBars({
                path: { customerId: this.customerId },
                query: { from: from.toISOString(), to: to.toISOString() },
            });
            if (error) {
                return this.errorDialog.showError({ bodyText: 'Could not retrieve transactions' });
            }
            this._transactionHistory = data;
        },
        autoRun: false,
        args: () => [this._timeWindow.from, this._timeWindow.to] as const,
    });
    _transactionListTask = new Task(this, {
        task: async ([from, to, page]) => {
            const { error, data } = await getTransactionList({
                path: { customerId: this.customerId },
                query: { skip: useSkip(page), from: from.toISOString(), to: to.toISOString() },
            });
            if (error) {
                return this.errorDialog.showError({
                    bodyText: 'Could not retrieve transactions for selected time span',
                });
            }
            this._transactionsData = data;
        },
        autoRun: false,
        args: () => [this._timeWindow.from, this._timeWindow.to, this._page] as const,
    });

    /* Internal state */
    @state()
    _selectedPeriod: GetTransactionPeriodList_TimeSeriesPeriodRequest = 'OneYear';

    @state()
    _graphVariant: GraphVariant = 'bar';

    @state()
    _transactionsData: GetTransactionListResponse | null = null;

    @state()
    _transactionHistory: GetTransactionBarsResponse | null = null;

    @state()
    _page = 1;

    @state()
    _skip = 0;

    @state()
    _timeWindow: { from: Date; to: Date } = { from: new Date(), to: new Date() };

    /* Actions */
    setTransactionHistoryPeriod = (period: GetTransactionPeriodList_TimeSeriesPeriodRequest) => {
        this._selectedPeriod = period;
        this._transactionHistoryTask.run([period]);
    };
    setGraphVariant = (key: GraphVariant) => (this._graphVariant = key);

    handlePagination(event: PliPagerUpdatedEvent) {
        const { page, skip } = event.detail;
        this._page = page;
        this._skip = skip;
        this._transactionListTask.run();
    }

    setTimeWindow = () => {
        const historyItems = this._transactionHistory?.items ?? [];
        const firstItem = historyItems[0];
        const lastItem = [...historyItems].pop();

        if (!lastItem) {
            return;
        }

        const from = new Date(firstItem.timestamp);
        const to = new Date(lastItem.timestamp);
        this._timeWindow = { from, to };
        this._transactionListTask.run([from, to, this._skip]);
    };

    onSlice = async (event: TransactionGraphSliceEvent) => {
        const { from, to } = event.detail;
        this._timeWindow = { ...event.detail };
        this._transactionHistorySliceTask.run([from, to]);
    };

    onReset = async () => {
        this._transactionHistoryTask.run([this._selectedPeriod]);
    };

    /* Render methods */
    renderButtonGroup = () => {
        const labels: { key: GetTransactionPeriodList_TimeSeriesPeriodRequest; label: string }[] = [
            { key: 'Today', label: '1D' },
            { key: 'OneWeek', label: '1W' },
            { key: 'OneMonth', label: '1M' },
            { key: 'ThreeMonths', label: '3M' },
            { key: 'OneYear', label: '1Y' },
            { key: 'ThreeYears', label: '3Y' },
            { key: 'Infinity', label: 'All' },
        ];

        const buttonVariant = (key: GetTransactionPeriodList_TimeSeriesPeriodRequest): PliButtonProps['variant'] =>
            key === this._selectedPeriod ? 'primary' : 'secondary';

        return html`
            <div class="flex gap-05">
                ${labels.map(
                    ({ key, label }) => html`
                        <pli-button
                            variant="${buttonVariant(key)}"
                            size="lg"
                            .onClick="${() => this.setTransactionHistoryPeriod(key)}"
                        >
                            ${label}
                        </pli-button>
                    `,
                )}
            </div>
        `;
    };

    renderSegmentControl = () => {
        const buttonVariant = (graphVariant: GraphVariant): PliButtonProps['variant'] =>
            graphVariant === this._graphVariant ? 'primary' : 'secondary';

        return html`
            <div class="flex">
                <pli-button
                    class="flex-1"
                    width="full"
                    size="lg"
                    radiusTopRight="0"
                    radiusBottomRight="0"
                    variant="${buttonVariant('bar')}"
                    .onClick="${() => this.setGraphVariant('bar')}"
                >
                    <pli-icon name="bar-chart-line-fill"></pli-icon>
                </pli-button>
                <pli-button
                    class="flex-1"
                    width="full"
                    size="lg"
                    radiusTopLeft="0"
                    radiusBottomLeft="0"
                    variant="${buttonVariant('line')}"
                    .onClick="${() => this.setGraphVariant('line')}"
                >
                    <pli-icon name="graph-up"></pli-icon>
                </pli-button>
            </div>
        `;
    };

    renderTransactionDialog(item: GetTransactionList_TransactionItem, openButtonTemplate: TemplateResult) {
        return html` <pli-dialog padding="collapsed" gradient="visible" modalHeight="fixed">
            ${openButtonTemplate}
            <transaction-dialog-content .item="${item}"></transaction-dialog-content>
            <pli-dialog-close-button slot="secondary-button">Close</pli-dialog-close-button>
        </pli-dialog>`;
    }

    renderTransactionsTable = () => {
        if (!this._transactionsData?.list) {
            return null;
        }

        const headerItems = defineHeaderItems({
            ['#']: {
                sortField: null,
                columnSpan: 2,
            },
            Amount: {
                sortField: null,
                columnSpan: 3,
            },
            Booked: {
                sortField: null,
                columnSpan: 6,
            },
            Action: {
                sortField: null,
                columnSpan: 1,
            },
        });

        const getDisplayedIndex = (index: number) => (this._page - 1) * 15 + (index + 1);

        const itemsMapped = this._transactionsData?.list.map((item, index) => ({
            index: getDisplayedIndex(index),
            ...item,
        }));

        const renderTemplate = (item: (typeof itemsMapped)[0]): TemplateResult => html`
            <tr>
                <td>
                    <pli-text><strong>${item.index}</strong></pli-text>
                </td>
                <td class="collapsed">
                    ${this.renderTransactionDialog(
                        item,
                        html`<pli-dialog-open-button slot="open-button" variant="text" width="hug-content"
                            ><displayed-amount
                                state="by-value"
                                value="${item.amount.value}"
                                currency="${item.amount.currency}"
                            ></displayed-amount>
                        </pli-dialog-open-button>`,
                    )}
                </td>
                <td>${getFormattedTime(new Date(item.booked))}</td>
                <td>
                    <div class="flex justify-between items-center">
                        ${this.renderTransactionDialog(
                            item,
                            html`<pli-dialog-open-button
                                iconEnd="card-heading"
                                slot="open-button"
                                variant="secondary"
                                width="hug-content"
                                >Details
                            </pli-dialog-open-button>`,
                        )}
                    </div>
                </td>
            </tr>
        `;

        const total = this._transactionsData?.total ?? 0;
        const page = this._page;
        const items = itemsMapped ?? [];

        return html`
            <pli-pager .items="${items}" page="${page}" total="${total}" @page-update="${this.handlePagination}">
                <pli-table
                    .headerItems="${headerItems}"
                    .items="${items}"
                    .renderTemplate="${renderTemplate}"
                ></pli-table>
            </pli-pager>
        `;
    };

    render() {
        const { renderButtonGroup, renderSegmentControl, renderTransactionsTable, onSlice, onReset } = this;

        return html`
            <pli-card>
                <div class="grid-vertical gap-1">
                    <slot name="header"></slot>
                    <div class="grid gap-1">
                        <div class="col-span-3">
                            <pli-card>
                                <pli-text><strong>Total Transaction Amount</strong></pli-text>
                                <displayed-amount
                                    state="positive"
                                    value="${this._transactionHistory?.computed.inbound.sum}"
                                ></displayed-amount>
                                <displayed-amount
                                    state="negative"
                                    value="${this._transactionHistory?.computed.outbound.sum}"
                                ></displayed-amount>
                            </pli-card>
                        </div>
                        <div class="col-span-3">
                            <pli-card>
                                <pli-text><strong>Average Transaction Amount</strong></pli-text>
                                <displayed-amount
                                    state="positive"
                                    value="${this._transactionHistory?.computed.inbound.average}"
                                ></displayed-amount>
                                <displayed-amount
                                    state="negative"
                                    value="${this._transactionHistory?.computed.outbound.average}"
                                ></displayed-amount>
                            </pli-card>
                        </div>
                        <div class="col-span-12">
                            <div class="grid">
                                <div class="col-span-6">${renderButtonGroup()}</div>
                                <div class="col-span-2 col-lg-span-1 col-start-11 col-lg-start-12">
                                    ${renderSegmentControl()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- graph -->
                    ${when(
                        this._transactionHistory?.items,
                        () =>
                            html`<transaction-graph
                                .transactionHistory="${this._transactionHistory.items}"
                                graphVariant="${this._graphVariant}"
                                @slice="${onSlice}"
                                @reset="${onReset}"
                            ></transaction-graph>`,
                    )}
                    <!-- history -->
                    ${renderTransactionsTable()}
                </div>
            </pli-card>
        `;
    }
}
