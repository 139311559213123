import { consume } from '@lit/context';
import { currentUserContext, CurrentUser } from 'context/current-user-context';
import { LitElement, html } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { PliInputChangeEvent } from 'pli/controllers/input-controller';
import { styles } from 'pli/styles';
import { UserModel } from 'user-model';
import 'pli/pli-user-bubble';
import 'pli/pli-textarea';
import 'pli/pli-button';
import { PliTextarea } from 'pli/pli-textarea';
import { CommentModel } from 'comment-model';
import './reply-comment-form';
import './comment-form-layout';

type CommentFormMode = 'reply' | 'edit' | 'initial';

@customElement('comment-form')
class CommentsForm extends LitElement {
    static styles = [styles.grid, styles.flex, styles.padding];

    @property({ attribute: false })
    createdTime: CommentModel['createdTime'];

    @property()
    value?: string;

    @property({ type: Boolean })
    isEditing: boolean = false;

    @property({ type: Boolean })
    disabled: boolean = false;

    @property({ type: Boolean })
    focusOnMount?: boolean;

    @consume({ context: currentUserContext })
    currentUser?: CurrentUser;

    @state()
    _profile: UserModel = new UserModel();

    @query('pli-textarea')
    textArea: PliTextarea;

    @state()
    formMode: CommentFormMode = 'initial';

    async connectedCallback() {
        super.connectedCallback();
        if (!this.currentUser) {
            return;
        }
        this._profile = await this.currentUser.get();
        this._initialValue = this.value;
    }

    protected updated(): void {
        if (this.focusOnMount || this.isEditing) {
            this.textArea.focusElement();
        }
    }

    resetValue() {
        this.value = this._initialValue;
    }

    @state()
    private _initialValue = '';

    setFormMode(mode: CommentFormMode) {
        this.formMode = mode;
        if (mode === 'initial') {
            return;
        }

        this.dispatchEvent(new CustomEvent(mode));
    }

    onChange = (event: PliInputChangeEvent) => {
        this.value = event.detail.value;
        const _event: CommentFormUpdateEvent = new CustomEvent('change', {
            composed: true,
            detail: {
                value: this.value,
            },
        });
        this.dispatchEvent(_event);
    };

    onReply(value: string) {
        if (this.value.length === 0) {
            return;
        }
        this.dispatchEvent(
            new CustomEvent('replySubmit', {
                composed: true,
                detail: {
                    value,
                },
            }),
        );
        this.setFormMode('initial');
    }

    onReplyCancel() {
        this.dispatchEvent(
            new CustomEvent('replyCancel', {
                composed: true,
            }),
        );
        this.setFormMode('initial');
    }

    onEditSubmit() {
        if (this.value.length === 0) {
            return;
        }
        this.dispatchEvent(
            new CustomEvent('editSubmit', {
                composed: true,
            }),
        );
        this.setFormMode('initial');
    }

    onEditCancel() {
        this.dispatchEvent(
            new CustomEvent('editCancel', {
                composed: true,
            }),
        );
        this.resetValue();
        this.setFormMode('initial');
    }

    get isInitialButtonGroupEnabled() {
        return this.formMode === 'initial' || this.formMode === 'reply';
    }

    render() {
        return html`
            <div class="grid-vertical gap-2">
                <comment-form-layout .createdTime="${this.createdTime}">
                    <pli-textarea
                        slot="input"
                        .disabled="${this.formMode !== 'edit'}"
                        .focusOnMount="${this.formMode === 'edit'}"
                        value="${this.value}"
                        placeholder="Your comment"
                        @change="${this.onChange}"
                    ></pli-textarea>
                    <div class="flex items-center gap-1" slot="button-group">
                        ${when(
                            this.isInitialButtonGroupEnabled,
                            () =>
                                html`<pli-button
                                        .disabled="${this.formMode === 'reply'}"
                                        variant="text"
                                        .onClick="${() => this.setFormMode('reply')}"
                                    >
                                        <pli-icon slot="icon-left" name="reply"></pli-icon>
                                        Reply
                                    </pli-button>
                                    <pli-button
                                        .disabled="${this.formMode === 'reply'}"
                                        variant="text"
                                        .onClick="${() => this.setFormMode('edit')}"
                                    >
                                        <pli-icon slot="icon-left" name="pencil"></pli-icon>
                                        Edit
                                    </pli-button>`,
                        )}
                        ${when(
                            this.formMode === 'edit',
                            () =>
                                html`<pli-button
                                        variant="text"
                                        .onClick="${() => {
                                            this.onEditCancel();
                                        }}"
                                        >Cancel</pli-button
                                    >
                                    <pli-button
                                        variant="text"
                                        .onClick="${() => this.onEditSubmit()}"
                                        .disabled="${this.value.length === 0}"
                                        >Save</pli-button
                                    >`,
                        )}
                        ${when(this.formMode === 'initial', () => html` <slot name="delete-dialog"></slot> `)}
                    </div>
                </comment-form-layout>
                ${when(
                    this.formMode === 'reply',
                    () =>
                        html`<div class="pl-3-5">
                            <reply-comment-form
                                @submit="${(event: CommentFormUpdateEvent) => this.onReply(event.detail.value)}"
                                @cancel="${this.onReplyCancel}"
                            ></reply-comment-form>
                        </div>`,
                )}
            </div>
        `;
    }
}

export type CommentFormUpdateEvent = CustomEvent<{ value: string }>;
